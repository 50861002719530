import React from "react";

export const context = {
  apiUrl:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_API_URL
      : process.env.REACT_APP_DEV_API_URL,
  blurRadius: 30,
  maxBlurValue: 180,
  domain: "https://uncove.com",
  publicVideoLimitDurationInSec: 45,
  privateVideoLimitDurationInSec: 2700,
  fileMaxSizeInGiga: 1.5,
  fileMinSizeInPixel: 500,
  videoChunkSize: 10,
  minCategories: 5,
  authPath: "/api/v1.6/auth/",
  usersPath: "/api/v1.4/users/",
  usersSuggestionPath: "/api/v1.4/users/suggestion/",
  newsPath: "/api/v1.1/news/",
  updateUserPath: "/api/v1.1/updateUser/",
  paypalPath: "/api/v1.0/paypal/",
  photosPath: "/api/v3.0/photos/",
  postsPath: "/api/v1.5/posts/",
  postsSuggestionPath: "/api/v1.1/posts/suggestion/",
  geolocationPath: "/api/v1.3/geolocation/",
  geolocationSuggestionPath: "/api/v1.3/geolocation/suggestion/",
  translationsPath: "/api/v1.1/translations/",
  settingsPath: "/api/v1.2/settings/",
  followPath: "/api/v1.1/follow/",
  mailPath: "/api/v1.1/mail/",
  subscribePath: "/api/v1.3/subscribe/",
  stripePath: "/api/v1.2/stripe/",
  securionpayPath: "/api/v1.1/securionpay/",
  buyPath: "/api/v1.2/buy/",
  notificationsPath: "/api/v1.1/notifications/",
  historicalPath: "/api/v1.0/historical/",
  videosPath: "/api/v2.2/videos/",
  conversationsPath: "/api/v1.2/conversations/",
  messagesPath: "/api/v2.1/messages/",
  opinionPath: "/api/v1.0/opinion/",
  transactionsPath: "/api/v1.3/transactions/",
  tagsPath: "/api/v1.0/tags/",
  blockingsPath: "/api/v1.0/blockings/",
  reportPath: "/api/v1.0/report/",
  passwordRecoveryPath: "/api/v1.0/passwordRecovery/",
  interestingsPath: "/api/v1.0/interestings/",
  errorsPath: "/api/v1.0/errors/",
  epochPath: "/api/v1.0/epoch/",
  identitiesPath: "/api/v1.1/identities/",
  ambassadorsPath: "/api/v1.0/ambassadors/",
  filesPath: "/api/v1.0/files/",
  translationPath: "/api/v1.0/translation/",
  serverStatusPath: "/api/v1.0/serverStatus/",
  categoriesPath: "/api/v1.0/categories/",
  associationsPath: "/api/v1.0/associations/",
  socialNetworksPath: "/api/v1.0/socialNetworks/",
  invoicePath: "/api/v1.0/invoice/",
  walletPath: "/api/v1.0/wallet/",
  wisePath: "/api/v1.0/wise/",
  liveNotifierPath: "/api/v2.0/live-notifier/",
  hitsPath: "/api/v1.0/hits/",
  bookingPath: "/api/v1.0/booking/",
  revolutPath: "/api/v1.0/revolut/",
};

export const AppContext = React.createContext(context);
