import React, { useEffect, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Box from "@material-ui/core/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";
import UserIdentity from "./userIdentity";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  idContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  idImg: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  postDetails: {
    width: "100%",
    fontSize: "22px",
  },
  thumb: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  },
  posTopRight: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  colorOrange: {
    color: "#ffa500",
  },
  colorSkyBlue: {
    color: "#87ceeb",
  },
  colorRed: {
    color: "#b20000",
  },
  refuseButton: {
    marginTop: "20px",
    marginLeft: "20px",
  },
  acceptButton: {
    marginTop: "20px",
    background: "#87ceeb",
  },
  descriptionTextField: {
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

export default function Identities() {
  const context = useContext(AppContext);
  const classes = useStyles();
  const [identities, setIdentities] = React.useState([]);
  const [identitiesLoaded, setIdentitiesLoaded] = React.useState(false);

  const authService = new AuthService();

  useEffect(() => {
    authService.setUrl(context.apiUrl + context.identitiesPath + "all");
    authService.request("get", {}, (response) => {
      setIdentities(response.data);
      setIdentitiesLoaded(true);
    });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Identities</h4>
            <p className={classes.cardCategoryWhite}>
              Here are all the identities
            </p>
          </CardHeader>
          <CardBody>
            {identitiesLoaded && identities.length === 0 && (
              <Box display="flex" justifyContent="center">
                <p>No identity document to check !</p>
              </Box>
            )}
            {identities
              .filter((e) => e.status !== "identityAccepted")
              .map((identity) => (
                <Card key={identity._id}>
                  <UserIdentity
                    identity={identity}
                    godFatheridentity={
                      identity.godFatherIdentityId
                        ? identities.filter(
                            (e) => e._id === identity.godFatherIdentityId
                          )[0]
                        : null
                    }
                  />
                </Card>
              ))}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
