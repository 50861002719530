import React, { useState, useContext, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { pink, green, orange, lightBlue } from "@material-ui/core/colors";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CloudImage from "components/CloudImage/CloudImage";

import AuthService from "../../AuthService";
import { AppContext } from "../../AppContext";

import IconIa from "../../img/icon_ia.png";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  idContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  idImg: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  postDetails: {
    width: "100%",
    fontSize: "22px",
    marginBottom: "10px",
  },
  thumb: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  },
  posTopRight: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  colorOrange: {
    color: "#ffa500",
  },
  colorSkyBlue: {
    color: "#87ceeb",
  },
  colorRed: {
    color: "#b20000",
  },
  warningButton: {
    marginTop: "20px",
    marginLeft: "20px",
    color: "#FFF",
    background: orange[500],
  },
  dangerButton: {
    marginTop: "20px",
    marginLeft: "20px",
    color: "#FFF",
    background: pink[500],
  },
  successButton: {
    marginTop: "20px",
    color: "#FFF",
    background: green[500],
  },
  actionButton: {
    marginLeft: "20px",
    color: "#FFF",
    background: lightBlue[500],
  },
  descriptionTextField: {
    width: "100%",
  },
  icon: {
    marginRight: "10px",
    height: "30px",
  },
  displayName: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  formControl: {
    width: "100%",
    marginBottom: "20px",
  },
  errorMessage: {
    margin: "10px 0",
    color: "#b20000",
    fontSize: "14px",
  },
};

const useStyles = makeStyles(styles);

export default function UserIdentity(props) {
  const context = useContext(AppContext);
  const [visible, setVisible] = useState(true);
  const [identity, setIdentity] = useState(props.identity);
  const [toggle, setToggle] = useState(false);
  const [responseSelected, setResponseSelected] = useState("");
  const [responseDetails, setResponseDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();
  const { godFatheridentity } = props;

  const status = {
    identityNotYetAccepted: (
      <label className={classes.colorOrange}>Pending</label>
    ),
    identityNotAccepted: <label className={classes.colorRed}>Refused</label>,
    identityAccepted: <label className={classes.colorSkyBlue}>Accepted</label>,
  };

  const resetResponses = () => {
    setResponseSelected("");
    setResponseDetails("");
  };

  const handleResponseChange = (e) => {
    setResponseSelected(e.target.value);
  };

  const handleDeleteUser = () => {
    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.usersPath);
    authService.request("delete", { userId: identity.userId }, () => {});
    setVisible(false);
  };

  const sendResponse = (identityId, status) => {
    if (
      status == "identityNotAccepted" &&
      (!responseSelected ||
        (responseSelected === "personalizedResponse" && !responseDetails))
    ) {
      setErrorMessage("Please enter a response");
      return;
    }

    identity.status = status;
    identity.response =
      responseSelected === "personalizedResponse"
        ? responseDetails
        : responses.filter((response) => response.value == responseSelected)[0]
            ?.label || "";
    setIdentity(identity);
    setToggle(!toggle);

    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.identitiesPath);
    authService.request(
      "put",
      {
        identityId,
        status,
        response: responseSelected,
        responseDetails:
          responseSelected === "personalizedResponse" ? responseDetails : "",
      },
      () => {
        responsesActions[responseSelected]();
        resetResponses();
      }
    );
  };

  const handleSwitchAi = (identity) => {
    identity.ia = true;
    setIdentity(identity);
    setToggle(!toggle);

    const authService = new AuthService();
    authService.setUrl(context.apiUrl + context.usersPath + "ia");
    authService.request(
      "put",
      {
        userId: identity.userId,
        value: true,
      },
      () => {}
    );
  };

  const responses = [
    {
      value: "mustBeLegalAgeToHaveCreatorAccount",
      label: "You must be of legal age to have a creator account on Uncove.",
    },
    {
      value: "yourIdentityDocumentsAreIllegible",
      label:
        "Your identity documents are illegible. Please take a clear photo of your documents along with a selfie of you with your face visible.",
    },
    {
      value: "clearSelfieHoldingYourIdentityDocument",
      label:
        "A clear selfie with your face visible holding your identity document is required for account verification.",
    },
    {
      value: "identityDocumentsMustMatchYourProfile",
      label:
        "The identity documents must match the person represented in the content published on your profile.",
    },
    {
      value: "documentMustBeOfficialIdentityDocument",
      label:
        "The document must be an official identity document (driver's license, passport, or national ID card).",
    },
    {
      value: "personalizedResponse",
      label: "Personalized response",
    },
  ];

  const responsesActions = {
    mustBeLegalAgeToHaveCreatorAccount: handleDeleteUser,
    yourIdentityDocumentsAreIllegible: () => {},
    clearSelfieHoldingYourIdentityDocument: () => {},
    identityDocumentsMustMatchYourProfile: () => {},
    documentMustBeOfficialIdentityDocument: () => {},
    personalizedResponse: () => {},
  };

  return (
    <>
      {visible && (
        <Box padding={1}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.postDetails}>
                <div className={classes.posTopRight} key={identity.status}>
                  {
                    status[
                      identity.status
                        ? identity.status
                        : "identityNotYetAccepted"
                    ]
                  }
                </div>
                <div className={classes.displayName}>
                  {identity.ia && <img className={classes.icon} src={IconIa} />}
                  {identity.firstName} {identity.lastName} ({identity.userName})
                  {!identity.ia && (
                    <Button
                      className={classes.actionButton}
                      onClick={() => {
                        handleSwitchAi(identity);
                      }}
                      variant="contained"
                    >
                      Switch IA
                    </Button>
                  )}
                </div>
              </div>
            </GridItem>

            {/* Uncover self photo*/}
            {identity.self && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={identity.self}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Uncover passport*/}
            {identity.passport && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={identity.passport}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Uncover ID front*/}
            {identity.idFront && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={identity.idFront}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Uncover ID back*/}
            {identity.idBack && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={identity.idBack}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {godFatheridentity && (
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.postDetails}>
                  <p>
                    Godfather : {godFatheridentity.firstName}{" "}
                    {godFatheridentity.lastName} ({godFatheridentity.userName})
                  </p>
                </div>
              </GridItem>
            )}

            {/* Godfather self photo*/}
            {godFatheridentity && identity.self && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={godFatheridentity.self}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Godfather ID Passport*/}
            {godFatheridentity && godFatheridentity.passport && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={godFatheridentity.passport}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Godfather ID Front*/}
            {godFatheridentity && godFatheridentity.idFront && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={godFatheridentity.idFront}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}

            {/* Godfather ID back*/}
            {godFatheridentity && godFatheridentity.idBack && (
              <GridItem xs={4} sm={4} md={4}>
                <div className={classes.idContainer}>
                  <CloudImage
                    className={classes.idImg}
                    src={godFatheridentity.idBack}
                    defaultHeight="auto"
                    defaultWidth="100%"
                    alt="thumb"
                  />
                </div>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.postDetails}>
                <div className={classes.posTopRight} key={identity.status}>
                  {
                    status[
                      identity.status
                        ? identity.status
                        : "identityNotYetAccepted"
                    ]
                  }
                </div>

                {identity.response && (
                  <p className={classes.colorRed}>{identity.response}</p>
                )}

                {(identity.status === "identityNotYetAccepted" ||
                  identity.status === "") && (
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Responses</InputLabel>
                      <Select
                        onChange={handleResponseChange}
                        value={responseSelected}
                      >
                        {responses.map((response) => (
                          <MenuItem key={response.value} value={response.value}>
                            {response.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {responseSelected == "personalizedResponse" && (
                      <TextField
                        className={classes.descriptionTextField}
                        onChange={(e) => {
                          setResponseDetails(e.target.value);
                        }}
                        label="Response Details"
                        type="text"
                        variant="outlined"
                        value={responseDetails}
                      />
                    )}

                    {errorMessage && (
                      <p className={classes.errorMessage}>{errorMessage}</p>
                    )}

                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Button
                          className={classes.successButton}
                          onClick={() => {
                            sendResponse(identity._id, "identityAccepted");
                          }}
                          variant="contained"
                        >
                          Accept
                        </Button>

                        <Button
                          className={classes.warningButton}
                          onClick={() => {
                            sendResponse(identity._id, "identityNotAccepted");
                          }}
                          variant="contained"
                        >
                          Refuse
                        </Button>
                      </Box>

                      <Box>
                        <Button
                          className={classes.dangerButton}
                          onClick={handleDeleteUser}
                          variant="contained"
                        >
                          Delete user
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </Box>
      )}
    </>
  );
}
